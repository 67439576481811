<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-4 col-12">Campaign Logs</div>
        <div class="
              col-lg-8 col-12
              text-end
              d-flex
              justify-content-end
              custom-flex-cloumn-mob
            ">
        </div>
    </div>
    <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
        <DataTable :value="campaignLogList" :scrollable="true" scrollHeight="flex" :paginator="true" :rows="30" :lazy="true"
            :rowHover="true" :totalRecords="totalRecords" @page="changePage($event)"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading" dataKey="cp1">
            <template v-if="!loading" #empty>No records found.</template>
            <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
            <Column field="name" header="Guest Name" headerStyle="width: 25%" bodyStyle="width: 25%">
                <template #body="{ data }">
                    <div>
                        <div class="text-capitalize">
                            {{ data.cp7 ? data.cp7 : "N/A" }}
                        </div>
                        <!-- <div class="text-capitalize">
                Marathi Name
              </div> -->
                    </div>
                </template>
            </Column>
            <Column field="votermobile" header="Guest Mobile No." headerStyle="width: 15%" bodyStyle="width: 15%">
                <template #body="{ data }">
                    <div>
                        {{ data.cp8 ? data.cp8 : "N/A" }}
                    </div>
                </template>
            </Column>
            <Column field="statussent" header="Sent" headerStyle="width: 10%" bodyStyle="width: 10%"
                class="d-flex justify-content-center">
                <template #body="{ data }">
                    <div>
                        <i class="pi pi-check-circle text-success" v-if="data.cp18 == 1" style="fontsize: 1rem"></i>
                        <i class="pi pi-times-circle text-danger" v-if="data.cp18 == 0" style="fontsize: 1rem"></i>
                    </div>
                </template>
            </Column>
            <Column field="statusdeliverd" header="Deliverd" headerStyle="width: 10%" bodyStyle="width: 10%"
                class="d-flex justify-content-center">
                <template #body="{ data }">
                    <div>
                        <i class="pi pi-check-circle text-success" v-if="data.cp20 == 1" style="fontsize: 1rem"></i>
                        <i class="pi pi-times-circle text-danger" v-if="data.cp20 == 0" style="fontsize: 1rem"></i>
                    </div>
                </template>
            </Column>
            <Column field="statusread" header="Read" headerStyle="width: 10%" bodyStyle="width: 10%"
                class="d-flex justify-content-center">
                <template #body="{ data }">
                    <div>
                        <i class="pi pi-check-circle text-success" v-if="data.cp22 == 1" style="fontsize: 1rem"></i>
                        <i class="pi pi-times-circle text-danger" v-if="data.cp22 == 0" style="fontsize: 1rem"></i>
                    </div>
                </template>
            </Column>
            <Column field="statusresponse" header="Call Response Name" headerStyle="width: 10%" bodyStyle="width: 10%"
                class="d-flex justify-content-center">
                <template #body="{ data }">
                    <div>
                        <div v-if="data.cp33">{{ data.cp33 }}</div>
                        <div v-if="data.cp36">{{ data.cp36 }}</div>
                        <div v-if="data.cp39">{{ data.cp39 }}</div>
                        <div v-if="data.cp42">{{ data.cp42 }}</div>
                        <div v-if="data.cp45">{{ data.cp45 }}</div>
                        <div v-if="data.cp48">{{ data.cp48 }}</div>
                        <div v-if="data.cp51">{{ data.cp51 }}</div>
                        <div v-if="data.cp54">{{ data.cp54 }}</div>
                        <div v-if="data.cp57">{{ data.cp57 }}</div>
                    </div>
                </template>
            </Column>
            <Column field="statusbutton" header="Button Name" headerStyle="width: 30%" bodyStyle="width: 30%"
                class="d-flex justify-content-center">
                <template #body="{ data }">
                    <div>
                        <span class="me-4" v-if="data.cp12 == 1">
                            <i class="pi pi-check-circle text-success me-2" style="fontsize: 1rem"
                                v-if="data.cp12 == 1"></i>
                            <i class="pi pi-times-circle text-danger me-2" style="fontsize: 1rem" v-if="data.cp12 != 1"></i>
                            <span>{{ data.cp13 }}</span></span>
                        <span class="me-4" v-if="data.cp12 == 2">
                            <i class="pi pi-check-circle text-success me-2" style="fontsize: 1rem"
                                v-if="data.cp12 == 2"></i>
                            <i class="pi pi-times-circle text-danger me-2" style="fontsize: 1rem" v-if="data.cp12 != 2"></i>
                            <span>{{ data.cp14 }}</span></span>
                        <span v-if="data.cp12 == 3">
                            <i class="pi pi-check-circle text-success me-2" style="fontsize: 1rem"
                                v-if="data.cp12 == 3"></i>
                            <i class="pi pi-times-circle text-danger me-2" style="fontsize: 1rem" v-if="data.cp12 != 3"></i>
                            <span>{{ data.cp15 }}</span></span>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script>
import ApiService from "../../../service/ApiService"
import { useRoute } from "vue-router";
export default {
    data() {
        return {
            campaignLogList: [],
            totalRecords: 0,
            loading: false,
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },

    mounted() {
        const route = useRoute();
        this.routeParam = route.params.campaignId;
        this.loading = true;
        this.getcampaignlogs({ co1: this.routeParam });
    },
    methods: {
        getcampaignlogs(item) {
            this.ApiService.getcampaignlogs(item).then((data) => {
                if (data.success === true) {
                    this.campaignLogList = data.data;
                    this.totalRecords = data.count;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.campaignLogList = null;
                    this.totalRecords = 0;
                }
            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.getcampaignlogs({ page_no: this.page_no, co1: this.routeParam });
        },
    },
};
</script>